export const GET_ALL_AUCTION_GAMES = "GET_ALL_AUCTION_GAMES";
export const ITEM_SEARCH_VALUE = "ITEM_SEARCH_VALUE";
export const CATEGORY_SEARCH_VALUE = "CATEGORY_SEARCH_VALUE";
export const ENTER_KEY_VAL = "ENTER_KEY_VAL";
export const GET_ALL_SELL_ITEMS = "GET_ALL_SELL_ITEMS";
export const GET_ALL_WATCH_LISTS = "GET_ALL_WATCH_LISTS";
export const GET_ALL_ENDING_AUCTIONS = "GET_ALL_ENDING_AUCTIONS";
export const GET_TOTAL_ENDING = "GET_TOTAL_ENDING";
export const GET_ALL_NEW_AUCTIONS = "GET_ALL_NEW_AUCTIONS";
export const GET_ALL_RESERVED_AUCTIONS = "GET_ALL_RESERVED_AUCTIONS";
export const GET_ALL_FEATURED_AUCTIONS = "GET_ALL_FEATURED_AUCTIONS";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_USER_BIDS = "GET_ALL_USER_BIDS";
export const GET_ALL_SOLD_ITEMS = "GET_ALL_SOLD_ITEMS";
export const GET_GAME_DETAIL = "GET_GAME_DETAIL";
export const EDIT_ITEM_DEATIL = "EDIT_ITEM_DEATIL";
export const GET_ALL_SEARCHED_CATEGORY = "GET_ALL_SEARCHED_CATEGORY";
export const EDIT_ITEM_VALUE = "EDIT_ITEM_VALUE";
export const GO_TO_SELL_ITEM = "GO_TO_SELL_ITEM";
export const RESET_TIME = "RESET_TIME";
export const GET_ALL_COMPLETED_AUCTIONS = "GET_ALL_COMPLETED_AUCTIONS";
export const GET_CLOSEST_TOTAL = "GET_CLOSEST_TOTAL";
export const LOST_DATA = "LOST_DATA";
export const GET_CURRENT_BID_AUCTIONS = "GET_CURRENT_BID_AUCTIONS";
export const USER_SAVED_SEARCHES = "USER_SAVED_SEARCHES";